import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { Heading, Section, TagLine } from "./Page";
import { Button } from "./Button";

const imageProps = {
  width: 400,
  height: 400,
  imgClassName: "rounded-full",
};

const FinishingTouchesSection = () => {
  return (
    <Section className="max-w-narrow">
      <Heading>Finishing Touches</Heading>
      <TagLine>Add something special to your wedding stationery</TagLine>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-6 my-10">
        <div>
          <StaticImage
            {...imageProps}
            src="../images/finishing-touches/Card Stock Choices.jpg" />
        </div>
        <div>
          <StaticImage
            {...imageProps}
            src="../images/finishing-touches/Extras-04.jpg" />
        </div>
        <div>
          <StaticImage
            {...imageProps}
            src="../images/finishing-touches/Extras-06.jpg" />
        </div>
        <div>
          <StaticImage
            {...imageProps}
            src="../images/finishing-touches/Extras-08.jpg" />
        </div>
        <div>
          <StaticImage
            {...imageProps}
            src="../images/finishing-touches/On the day-15.jpg" />
        </div>
        <div>
          <StaticImage
            {...imageProps}
            src="../images/finishing-touches/Website Wax seals-2.jpg" />
        </div>
      </div>
      <div className="text-center my-10" >
        <Button as={Link} to="/weddings/finishing-touches">Find out more about finishing touches</Button>
      </div>
    </Section>
  );
};

export {
  FinishingTouchesSection,
};
