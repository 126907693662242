import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { Layout } from "../../components/Layout";
import { PageSeo } from "../../components/Seo";
import { Copy } from "../../components/Copy";
import { Carousel } from "../../components/Carousel";
import { InviteProcessSection } from "../../components/InviteProcessSection";
import { TestimonialsSection } from "../../components/TestimonialsSection";
import { FinishingTouchesSection } from "../../components/FinishingTouchesSection";
import { Container, Heading, Section } from "../../components/Page";

const CustomPage = () => {
  return (
    <Layout>
      <PageSeo tabTitleSegments={["Custom Designs"]} title="Custom Designs" />
      <Carousel height={500} items={[
        {
          image: <StaticImage
            src="../../images/custom/St Peters Church Walpole venue invite-11.jpg"
            alt="Church venue custom design"
            width={1400}
          />
        },
        {
          image: <StaticImage
            src="../../images/custom/Venue shopify.jpg"
            alt="Manor venue custom design"
            width={1400}
          />
        },
      ]} />
      <Container>
        <Section className="max-w-narrow">
          <Heading>Custom Designs</Heading>
          <Copy>
            <p>
              If you are looking for something unique for your wedding day, Kimberley can create you a bespoke design as a part of the custom design service.
              Some wedding stationers use images from other artists when creating bespoke wedding invitations, but Kimberley will illustrate your design from scratch, working with you along the way to ensure the design meets your requirements.
            </p>
            <p>
              The possibilities are endless, below are some custom designs Kimberley has created:
            </p>
            <ul>
              <li>Church venue</li>
              <li>Manor venue</li>
              <li>Swallow bird theme</li>
              <li>Wizarding school theme</li>
              <li>Blosom tree theme</li>
            </ul>
            <p>
              The custom design service also extends to Kimberley's <Link to="/weddings/on-the-day">on the day</Link> offerings too.
            </p>
            <p>
              Customers who use this service are sent proofs, minor adjustments can then be made to ensure you're happy. Depending on the design, sometimes multiple versions will be created for you to choose from.
            </p>
            <p>
              And as an added bonus, you will also receive the original painting with your names and wedding date!
            </p>
          </Copy>
        </Section>
        <InviteProcessSection />
        <FinishingTouchesSection />
        <TestimonialsSection />
      </Container>
    </Layout>
  );
};

export default CustomPage;
