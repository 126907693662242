import React from "react";

import { useSiteMetadata } from "../hooks/SiteMetadataQuery";
import { Copy } from "./Copy";
import { Heading, Section, TagLine } from "./Page";

const InviteProcessSection = () => {
  const { getAQuoteUrl } = useSiteMetadata();
  return (
    <Section className="max-w-narrow">
      <Heading>How It Works</Heading>
      <TagLine>Interested in wedding stataionery? This is the process:</TagLine>
      <div className="divide-y divide-gray-300">
        <InviteProcessRow step={1}>
          <p>
            Complete the <a className="font-bold" rel="noreferrer" target="_blank" href={getAQuoteUrl}> quote form</a>.
          </p>
        </InviteProcessRow>
        <InviteProcessRow step={2}>
          <p>
            Kimberley will get in touch to arrange a very informal Zoom call (or telephone call if you prefer).
          </p>
          <p>
            It's much easier to have call then to keep going back-and-forth with emails.
            It's also a great opportunity for Kimberley to get to know her clients!
          </p>
        </InviteProcessRow>
        <InviteProcessRow step={3}>
          <p>
            After the Zoom call, Kimberley will send you a free itemised no-obligation quote!
          </p>
        </InviteProcessRow>
      </div>
    </Section>
  );
};

const InviteProcessRow = ({ step, children }) => {
  return (
    <div className="flex items-center justify-start py-4">
      <div className="shrink-0 h-16 w-16 rounded-full bg-gray-300 relative mr-5">
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 leading-none font-mono text-xl text-white">
          {step}
        </div>
      </div>
      <Copy>{children}</Copy>
    </div>
  );
};

export {
  InviteProcessSection,
};
